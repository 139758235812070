/* BlogSkeleton.css */


.blog-box {
    margin-bottom: 20px;
  }
  
  .skeleton-image {
    width: 100%;
    height:300px; 
    background-color: #ddd; 
    /* width: 100%;
    height: 100%; */
    object-fit: cover;
  }
  
  .skeleton-category {
    width: 50%;
    height: 16px; 
    margin-top: 10px;
    background-color: #ddd;
  }
  
  .skeleton-date {
    width: 30%;
    height: 14px; 
    background-color: #ddd;
    margin-bottom: 5px;
  }
  
  .skeleton-calendar {
    width: 15px;
    height: 15px;
    background-color: #ddd;
    margin-left: 5px;
    border-radius: 50%;
    display: inline-block;
  }
  
  .skeleton-title {
    width: 80%;
    height: 24px; 
    margin-top: 10px;
    background-color: #ddd;
  }
  
  .skeleton-description {
    width: 100%;
    height: 50px; 
    margin-top: 10px;
    background-color: #ddd;
  }
  
  .skeleton-tags {
    width: 60%;
    height: 20px; 
    margin-top: 10px;
    background-color: #ddd;
  }
  
  /* Additional styling can be added based on your design requirements */
  